<template>
  <div class="card dex-card">
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <b-row>
          <b-col sm="12" md="6" lg="6">
            <div class="token-pair-address-name-container token-pair-details">
              <span class="token-pair-address-one">
                {{ tokenPairData ? tokenPairData.token0.tokenSymbol : "" }}
              </span>
              <span class="token-pair-address-separator"> / </span>
              <span class="token-pair-address-two">
                {{ tokenPairData ? tokenPairData.token1.tokenSymbol : "" }}
              </span>
            </div>
          </b-col>
          <b-col sm="12" md="6" lg="6">
            <div class="token-price-data-container token-pair-details">
              <div class="token-pricing-container d-flex">
                <div class="token-price-value">
                  {{
                    tokenMetadata
                      ? tokenMetadata.tokenPrice
                      : 0.0 | formatCurrency(12, 3, "$")
                  }}
                </div>
                <div class="token-price-value-last-24"></div>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="main-token-details-container d-flex">
          <div class="token-pair-details">
            <div class="token-pair-details-sub d-flex">
              <div class="token-name">
                {{ tokenPairData ? tokenPairData.mainToken.tokenName : "" }}
              </div>
              <div
                class="token-address"
                v-b-tooltip.hover
                :title="
                  tokenPairData ? tokenPairData.mainToken.tokenAddress : ''
                "
                variant="outline-dark"
              >
                Token :
                {{
                  tokenPairData
                    ? tokenPairData.mainToken.tokenAddress
                    : "" | truncateString(7)
                }}
                <feather-icon
                  icon="CopyIcon"
                  @click="doCopy(tokenPairData.mainToken.tokenAddress, 'Token')"
                />
              </div>
              <div
                class="token-pair"
                v-b-tooltip.hover
                :title="tokenPairData ? tokenPairData.pairAddress : ''"
                variant="outline-dark"
              >
                Pair :
                {{
                  tokenPairData
                    ? tokenPairData.pairAddress
                    : "" | truncateString(7)
                }}
                <feather-icon
                  icon="CopyIcon"
                  @click="doCopy(tokenPairData.pairAddress, 'Pair')"
                />
              </div>
            </div>

            <div class="token-info-links d-flex">
              <div class="bsc-link links">
                <a
                  :href="`https://bscscan.com/token/${tokenAddress}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/bscscan.png"
                    width="25"
                    height="25"
                    rounded="circle"
                  />
                </a>
              </div>
              <div class="unicrypt-link links">
                <a
                  :href="`https://app.unicrypt.network/amm/pancake-v2/pair/${tokenAddress}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/unicrypt_v3.svg"
                    width="25"
                    height="25"
                    rounded="circle"
                  />
                </a>
              </div>
              <div class="mudra-link links">
                <a
                  :href="`https://mudra.website/?certificate=yes&type=0&lp=${tokenAddress}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/mudra.png"
                    width="25"
                    height="25"
                    rounded="circle"
                  />
                </a>
              </div>
              <div class="team-lock-link links">
                <a
                  :href="`https://www.team.finance/view-coin/${tokenAddress}?name=${
                    tokenPairData ? tokenPairData.mainToken.tokenName : ''
                  }&symbol=${
                    tokenPairData ? tokenPairData.mainToken.tokenSymbol : ''
                  }`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/team-bright.png"
                    width="25"
                    height="25"
                    rounded="circle"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BImg, BRow, BCol, VBTooltip } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: { BSpinner, BImg, BRow, BCol },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState("tokenPairData", {
      tokenPairData: (state) => state.tokenPairData,
      isLoading: (state) => state.isLoading,
    }),
    ...mapState("tokenMetadata", {
      tokenMetadata: (state) => state.tokenMetadata,
      isTokenMetadataLoading: (state) => state.isLoading,
    }),
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
  },
  mounted() {
    //this.$store.dispatch("tokenPairData/getTokenPairData", this.tokenAddress);
  },
  methods: {
    doCopy(value, name) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${name} contract address copied`,
              icon: "BellIcon",
              variant: 'success'
            },
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: e + "Can not copy!",
              icon: "BellIcon",
            },
          });
        }
      );
    },
  },
};
</script>
<style></style>
