<template>
  <div class="card dex-card info-qual-row">
    <div class="title">Token Liquidity Lock Status</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container-divider">
          <div class="info-title">Top LP Token Holder Details</div>
          <div class="info-container">
            <div class="name">Token Address:</div>
            <div
              class="value"
              v-b-tooltip.hover
              :title="lpHoldersList[0] ? lpHoldersList[0].address : ''"
              variant="outline-dark"
            >
              {{
                lpHoldersList[0]
                  ? lpHoldersList[0].address
                  : "" | truncateString(12)
              }}
            </div>
          </div>

          <div class="info-container">
            <div class="name">LP Holding Amount:</div>
            <div class="value">
              {{
                lpHoldersList[0]
                  ? lpHoldersList[0].value
                  : 0.0 | formatNumber(4, 12)
              }}
              LP Tokens
            </div>
          </div>

          <div class="info-container">
            <div class="name">LP Holding Percentage:</div>
            <div class="value">
              {{
                lpHoldersList[0]
                  ? lpHoldersList[0].percentage
                  : 0.0 | formatNumber(2, 12)
              }}
              %
            </div>
          </div>
        </div>

        <div class="alert-container">
          <div
            class="alerts-list"
            v-for="(data, index) in warnings"
            :key="index"
          >
            <b-alert :variant="data.type" show class="mb-0">
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                {{ data.message }}
              </div>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BAlert, VBTooltip } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner, BAlert },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState("tokenLiquidityLockInfo", {
      lpHoldersList: (state) => state.lpHoldersList,
      isLiquidityLocked: (state) => state.isLiquidityLocked,
      isLoading: (state) => state.isLoading,
      warnings: (state) => state.warnings,
    }),
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
  },
  mounted() {
    this.$store.dispatch(
      "tokenLiquidityLockInfo/getTokenLiquidityLockData",
      this.tokenAddress
    );
  },
};
</script>
<style></style>
