<template>
  <div class="card dex-card">
    <div class="title">Token Pancakeswap V2 Liquidity Info</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container">
          <div class="name">Token Price:</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.tokenPriceInUSD
                : 0.0 | formatCurrency(12, 4, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">BNB Price:</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.bnbPriceInUSD
                : 0.0 | formatCurrency(3, 4, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <!-- <div class="name">
            Token Amount ({{
              tokenLiquidityData
                ? tokenLiquidityData.tokenPrimaryData.tokenSymbol
                : ""
            }}):
          </div> -->
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityTokenAmount
                : 0.0 | formatNumber(3, 7)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">BNB Amount (BNB):</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityBnbAmount
                : 0.0 | formatNumber(3, 7)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Token Value (USD):</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityTokenValueInUSD
                : 0.0 | formatCurrency(3, 4, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">BNB Value (USD):</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityBnbValueInUSD
                : 0.0 | formatCurrency(3, 4, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Total Liquidity Value (USD):</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityTotalPoolValueInUSD
                : 0.0 | formatCurrency(3, 4, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Token Liquidity Percentage:</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityTokenPercentage
                : 0.0 | formatNumber(2, 7)
            }}%
          </div>
        </div>
        <div class="info-container">
          <div class="name">BNB Liquidity Percentage:</div>
          <div class="value">
            {{
              tokenLiquidityData
                ? tokenLiquidityData.liquidityBnbPercentage
                : 0.0 | formatNumber(2, 7)
            }}
            %
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  computed: {
    ...mapState("tokenLiquidityData", {
      tokenLiquidityData: (state) => state.tokenLiquidityData,
      isLoading: (state) => state.isLoading
    })
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber
  },
  mounted() {
    this.$store.dispatch(
      "tokenLiquidityData/getTokenLiquidityInfo",
      this.tokenAddress
    );
  }
};
</script>
<style></style>
