<template>
  <div class="card dex-card">
    <div class="title">Token Ownership Details</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container-row">
          <b-row>
            <b-col sm="12" md="12" lg="4">
              <div class="ownership-info-sub-container">
                <div class="ownership-info-container">
                  <div class="ownership-data">Current Owner Address</div>
                  <div class="ownership-value">
                    {{
                      tokenOwnershipInfo
                        ? tokenOwnershipInfo.ownerAddress
                        : "" | truncateString(12)
                    }}
                  </div>
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="12" lg="4">
              <div class="ownership-info-container">
                <div class="ownership-data">Owner Token Holding Amount</div>
                <div class="ownership-value">
                  {{
                    tokenOwnershipInfo
                      ? tokenOwnershipInfo.devWalletHoldings
                      : 0 | formatNumber(2)
                  }}
                </div>
              </div>
            </b-col>
            <b-col sm="12" md="12" lg="4">
              <div class="ownership-info-container">
                <div class="ownership-data">Current Owner Address</div>
                <div class="ownership-value">
                  {{
                    tokenOwnershipInfo
                      ? tokenOwnershipInfo.devWalletHoldingPercentage
                      : 0 | formatNumber(2)
                  }}
                  %
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="12" lg="12">
              <div class="alert-container mt-2">
                <div
                  class="alerts-list"
                  v-for="(data, index) in warnings"
                  :key="index"
                >
                  <b-alert :variant="data.type" show class="mb-0">
                    <div class="alert-body">
                      <feather-icon icon="InfoIcon" class="mr-50" />
                      {{ data.message }}
                    </div>
                  </b-alert>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BRow, BCol, BAlert } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner, BRow, BCol, BAlert },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  computed: {
    ...mapState("tokenOwnershipInfo", {
      tokenOwnershipInfo: (state) => state.tokenOwnershipInfo,
      warnings: (state) => state.warnings,
      isLoading: (state) => state.isLoading
    })
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber
  },
  mounted() {
    this.$store.dispatch(
      "tokenOwnershipInfo/getTokenOwnershipData",
      this.tokenAddress
    );
  }
};
</script>
<style>
.ownership-value {
  margin-top: 5px;
}
.ownership-info-container {
  margin-top: 10px;
}
</style>
