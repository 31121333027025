<template>
  <div class="card dex-card">
    <div class="title">Token Metadata</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container">
          <div class="name">Token Name:</div>
          <div class="value">
            {{ tokenMetadata ? tokenMetadata.tokenName : "" }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Token Symbol:</div>
          <div class="value">
            {{ tokenMetadata ? tokenMetadata.tokenSymbol : "" }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Market Cap:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.tokenMarketCap
                : 0.0 | formatCurrency(3, 3, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Token Price:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.tokenPrice
                : 0.0 | formatCurrency(12, 3, "$")
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Circulation Supply:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.circulationSupply
                : 0.0 | formatNumber(2, 7)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Total Supply:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.tokenTotalSupply
                : 0.0 | formatNumber(2, 7)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Burned Tokens:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.actualBurnedTokenBalance
                : 0.0 | formatNumber(2, 7)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Burned Percentage:</div>
          <div class="value">
            {{
              tokenMetadata
                ? tokenMetadata.burnPercentage
                : 0.0 | formatNumber(2, 7)
            }}%
          </div>
        </div>
        <div class="info-container">
          <div class="name">Token Address:</div>
          <div
            class="value"
            v-b-tooltip.hover
            :title="tokenMetadata ? tokenMetadata.tokenAddress : ''"
            variant="outline-dark"
          >
            {{
              tokenMetadata
                ? tokenMetadata.tokenAddress
                : "" | truncateString(7)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, VBTooltip } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  computed: {
    ...mapState("tokenMetadata", {
      tokenMetadata: (state) => state.tokenMetadata,
      isLoading: (state) => state.isLoading,
    }),
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
  },
  mounted() {
    this.$store.dispatch("tokenMetadata/getTokenMetadata", this.tokenAddress);
  },
};
</script>
<style></style>
