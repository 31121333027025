<template>
  <div class="card dex-card">
    <div class="title">Token Liquidity Pair Info</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container-divider">
          <div class="info-title">Token0 Details</div>
          <div class="info-container">
            <div class="name">Token Name:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token0.tokenName
                  : "" | truncateString(12)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Symbol:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token0.tokenSymbol
                  : "" | truncateString(12)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Decimals:</div>
            <div class="value">
              {{ tokenPairData ? tokenPairData.token0.tokenDecimals : 0 }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Total Supply:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token0.tokenTotalSupply
                  : 0.0 | formatNumber(3, 7)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Address:</div>
            <div
              class="value"
              v-b-tooltip.hover
              :title="tokenPairData ? tokenPairData.token0.tokenAddress : ''"
              variant="outline-dark"
            >
              {{
                tokenPairData
                  ? tokenPairData.token0.tokenAddress
                  : "" | truncateString(7)
              }}
            </div>
          </div>
        </div>

        <div class="info-container-divider">
          <div class="info-title">Token1 Details</div>
          <div class="info-container">
            <div class="name">Token Name:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token1.tokenName
                  : "" | truncateString(12)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Symbol:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token1.tokenSymbol
                  : "" | truncateString(12)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Decimals:</div>
            <div class="value">
              {{ tokenPairData ? tokenPairData.token1.tokenDecimals : 0 }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Total Supply:</div>
            <div class="value">
              {{
                tokenPairData
                  ? tokenPairData.token1.tokenTotalSupply
                  : 0.0 | formatNumber(3, 7)
              }}
            </div>
          </div>
          <div class="info-container">
            <div class="name">Token Address:</div>
            <div
              class="value"
              v-b-tooltip.hover
              :title="tokenPairData ? tokenPairData.token1.tokenAddress : ''"
              variant="outline-dark"
            >
              {{
                tokenPairData
                  ? tokenPairData.token1.tokenAddress
                  : "" | truncateString(7)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, VBTooltip } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner },
  props: ["tokenAddress"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("tokenPairData", {
      tokenPairData: (state) => state.tokenPairData,
      isLoading: (state) => state.isLoading,
    }),
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
  },
  mounted() {
    this.$store.dispatch("tokenPairData/getTokenPairData", this.tokenAddress);
  },
};
</script>
<style></style>
