<template>
  <div>
    <b-row>
      <b-col sm="12" md="3" lg="3">
        <TokenInfoCardVue :token-address="tokenAddress" />
        <TokenLiquidityInfoVue :token-address="tokenAddress" />
        <TokenPairInfo :token-address="tokenAddress" />
      </b-col>
      <b-col sm="12" md="9" lg="9">
        <TokenMainDetails :token-address="tokenAddress" />
        <b-row>
          <b-col sm="12" md="12" lg="6" class="info-qual-row">
            <TokenHoneypotInfo :token-address="tokenAddress" />
          </b-col>
          <b-col sm="12" md="12" lg="6" class="info-qual-row">
            <TokenLiquidityLockData :token-address="tokenAddress" />
          </b-col>

          <b-col sm="12" md="12" lg="12" class="info-full-row">
            <TokenOwnershipInfo :token-address="tokenAddress" />
          </b-col>
        </b-row>
        <RecentTransactionList :token-address="tokenAddress" />
      </b-col>
      <!-- <b-col sm="12" md="2" lg="2"> fd </b-col> -->
    </b-row>
  </div>
</template>

<script>
import TokenInfoCardVue from "./ape/TokenInfoCard.vue";
import TokenLiquidityInfoVue from "./ape/TokenLiquidityInfo.vue";
import TokenPairInfo from "./ape/TokenPairInfo.vue";
import TokenMainDetails from "./ape/TokenMainDetails.vue";
import RecentTransactionList from "./ape/RecentTransactionList.vue";
import TokenHoneypotInfo from "./ape/TokenHoneypotInfo.vue";
import TokenLiquidityLockData from "./ape/TokenLiquidityLockData.vue";
import TokenOwnershipInfo from "./ape/TokenOwnershipInfo.vue";

import { BRow, BCol, VBTooltip } from "bootstrap-vue";
export default {
  data() {
    return {
      values: [50, 50],
      tokenAddress: ""
    };
  },
  components: {
    BRow,
    BCol,
    TokenInfoCardVue,
    TokenLiquidityInfoVue,
    TokenPairInfo,
    TokenMainDetails,
    RecentTransactionList,
    TokenHoneypotInfo,
    TokenLiquidityLockData,
    TokenOwnershipInfo
    // BFormRating,
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  computed: {},
  created() {
    this.tokenAddress = this.$route.params.token;
  },
  mounted() {}
};
</script>

<style>
.dark-layout .main-card {
  /* background-color: #1d2c56 !important; */
  background-color: #142028 !important;
  border-radius: 15px;
}
.dark-layout .center-card {
  /* background-color: #1d2c56 !important; */
  background-color: #142028 !important;
  border-radius: 15px;
  padding: 10px;
}
.card-body {
  padding: 0.5rem;
}
body.dark-layout {
  background-color: #0b1217 !important;
}
.left-card-header {
  font-size: 14px;
  font-weight: 900;
}
.left-card-details {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 14px;
}
.center-card-details {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 5px 5px;
}
.dextscore-card {
  font-weight: bolder;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.left-side-icon {
  margin-top: 5px;
  margin-right: 3px;
}
.left-side-icon-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 2px;
  margin-top: 4px;
  margin-right: 5px;
}
.community-details-success {
  color: rgb(0, 105, 44);
}
.token-name {
  color: #818ea3 !important;
}
.center-card-img {
  margin-right: 5px;
}
</style>
