<template>
  <div class="card dex-card info-qual-row">
    <div class="title">Token Honeypot Check Info</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="info-container">
          <div class="name">Buy Gas Fee:</div>
          <div class="value">
            {{ honeypotData ? honeypotData.BuyGas : 0.0 | formatNumber(3, 12) }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Buy Gas Tax:</div>
          <div class="value">
            {{ honeypotData ? honeypotData.BuyTax : 0.0 | formatNumber(3, 12) }}
            %
          </div>
        </div>
        <div class="info-container">
          <div class="name">Max Transaction Amount:</div>
          <div class="value">
            {{
              honeypotData
                ? honeypotData.MaxTxAmount
                : 0.0 | formatNumber(3, 12)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Max Transaction Amount (BNB):</div>
          <div class="value">
            {{
              honeypotData
                ? honeypotData.MaxTxAmountBNB
                : 0.0 | formatNumber(3, 12)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Sell Gas:</div>
          <div class="value">
            {{
              honeypotData ? honeypotData.SellGas : 0.0 | formatNumber(3, 12)
            }}
          </div>
        </div>
        <div class="info-container">
          <div class="name">Sell Tax:</div>
          <div class="value">
            {{
              honeypotData ? honeypotData.SellTax : 0.0 | formatNumber(3, 12)
            }}
            %
          </div>
        </div>

        <div class="alert-container">
          <div
            class="alerts-list"
            v-for="(data, index) in warnings"
            :key="index"
          >
            <b-alert :variant="data.type" show class="mb-0">
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                {{ data.message }}
              </div>
            </b-alert>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BAlert } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner, BAlert },
  props: ["tokenAddress"],
  data() {
    return {};
  },
  computed: {
    ...mapState("tokenHoneypotInfo", {
      honeypotData: (state) => state.honeypotData,
      isLoading: (state) => state.isLoading,
      warnings: (state) => state.warnings
    })
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber
  },
  mounted() {
    this.$store.dispatch(
      "tokenHoneypotInfo/getTokenHoneypotData",
      this.tokenAddress
    );
  }
};
</script>
<style></style>
