<template>
  <div class="card dex-card">
    <div class="title">Latest Token Transactions</div>
    <div class="body">
      <div v-if="isLoading">
        <div class="text-center d-flex justify-content-center">
          <div>
            <b-spinner
              style="width: 4rem; height: 4rem"
              label="Token metadata Loading"
              type="grow"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="recent-token-transactions">
          <b-table
            responsive
            :items="recentTokenTransactions"
            :fields="fields"
            class="mb-0 recent-transaction-table"
          >
            <template #cell(blockNumber)="data">
              <span class="tx-table-value text-nowrap">
                <b-badge pill variant="light-info">{{ data.value }}</b-badge>
              </span>
            </template>

            <template #cell(transactionType)="data">
              <span class="tx-table-value text-nowrap">
                <span v-if="data.value == 'sell'">
                  <b-badge pill variant="light-danger">{{
                    data.value
                  }}</b-badge>
                </span>
                <span v-else>
                  <b-badge pill variant="light-success">{{
                    data.value
                  }}</b-badge>
                </span>
              </span>
            </template>

            <template #cell(from)="data">
              <span
                class="tx-table-value text-nowrap"
                v-b-tooltip.hover
                :title="data.value"
                variant="outline-dark"
              >
                {{ data.value | truncateString(7) }}
              </span>
            </template>

            <template #cell(to)="data">
              <span
                class="tx-table-value text-nowrap"
                v-b-tooltip.hover
                :title="data.value"
                variant="outline-dark"
              >
                {{ data.value | truncateString(7) }}
              </span>
            </template>

            <template #cell(actualTokenValue)="data">
              <span
                class="
                  tx-table-value
                  table-number-column
                  d-flex
                  align-items-end
                  justify-content-end
                "
              >
                {{ data.value | formatNumber(2, 7) }}
              </span>
            </template>

            <template #cell(transactionValueInUSD)="data">
              <span
                class="
                  tx-table-value
                  table-number-column
                  d-flex
                  align-items-end
                  justify-content-end
                "
              >
                {{ data.value | formatCurrency(2, 7) }}
              </span>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell(actions)="data">
              <span class="d-flex align-items-end justify-content-end">
                <a
                  :href="`https://bscscan.com/tx/${data.item.transactionHash}`"
                  target="_blank"
                >
                  <b-img
                    src="@/assets/icons/telescope.png"
                    width="20"
                    height="20"
                    rounded="circle"
                  />
                </a>
              </span>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BTable, BBadge, BImg, VBTooltip } from "bootstrap-vue";
import { mapState } from "vuex";
import { truncateString, formatCurrency, formatNumber } from "../../filters";
export default {
  components: { BSpinner, BTable, BBadge, BImg },
  props: ["tokenAddress"],
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fields: [
        {
          key: "blockNumber",
          label: "Block#",
        },
        {
          key: "transactionType",
          label: "Type",
        },
        {
          label: "From",
          key: "from",
        },
        {
          label: "To",
          key: "to",
        },
        {
          label: "Token Amount",
          key: "actualTokenValue",
        },
        {
          label: "Token Value",
          key: "transactionValueInUSD",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      items: [],
    };
  },
  computed: {
    ...mapState("recentTokenTransactions", {
      recentTokenTransactions: (state) => state.recentTokenTransactions,
      isLoading: (state) => state.isLoading,
    }),
  },
  filters: {
    truncateString,
    formatCurrency,
    formatNumber,
  },
  mounted() {
    this.$store.dispatch(
      "recentTokenTransactions/getRecentTokenTransaction",
      this.tokenAddress
    );
  },
};
</script>
<style>
.recent-transaction-table th {
  font-size: 8px !important;
}
</style>
